import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import 'react-tabs/style/react-tabs.css';
import Modal from 'components/Modal';
import MaterialTable from 'material-table';
import CollapsibleContent from 'components/CollapsibleContent';
import IconButton from '@material-ui/core/IconButton';
import ReactTooltip from 'react-tooltip';
import { Delete as DeleteIcon, AddBox as AddBoxIcon } from '@material-ui/icons';
import Select from 'react-select';
import { addNewNotification } from 'store/actions/notification_actions';
import downloadFile from 'utils/downloadFile';
import { BUILD_TOOLS } from 'utils/consts';

const SubmitTestCaseModal = ({
  elements,
  onClose,
  onSubmit,
  environment,
  commonFields,
  parent: { tools: buildTool, envlist },
}) => {
  const MAX_ROWS = 50;

  const initialTestDataState = () => {
    return elements.map((testCase) => {
      const updatedTestCase = { ...testCase };
      const allEnvs = [
        ...new Set([
          ...Object.keys(testCase),
          ...envlist.map(({ env }) => `testdata_${env.toLowerCase()}`),
        ]),
      ];
      allEnvs
        .filter((key) => key.startsWith('testdata_'))
        .forEach((envKey) => {
          if (!updatedTestCase[envKey]) {
            updatedTestCase[envKey] = [{ _tags: '', _name: '' }];
            updatedTestCase.columnsOrder = {
              ...updatedTestCase.columnsOrder,
              [envKey]: [],
            };
          } else {
            updatedTestCase[envKey] = updatedTestCase[envKey].map((row) => {
              if (row.testiterationfile) {
                const { testiterationfile, ...rest } = row;
                return rest;
              }
              return row;
            });
          }
        });
      return updatedTestCase;
    });
  };

  const [testDataState, setTestDataState] = useState(initialTestDataState);

  const [filteredTestDataState, setFilteredTestDataState] =
    useState(initialTestDataState);

  const [iterationFiles] = useState(() => {
    if (buildTool === BUILD_TOOLS.PLAYWRIGHT) return null;

    const files = {};
    elements.forEach((testCase) => {
      const testCaseId = testCase.id;
      files[testCaseId] = {};
      Object.keys(testCase)
        .filter((key) => key.startsWith('testdata_'))
        .forEach((envKey) => {
          const iterationFile = testCase[envKey].find(
            (row) => row.testiterationfile
          );
          if (iterationFile) {
            files[testCaseId][envKey] = iterationFile.testiterationfile;
          }
        });
    });
    return files;
  });

  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showConfirmClose, setShowConfirmClose] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [checkedRows, setCheckedRows] = useState(() => {
    const initialCheckedRows = {};
    elements.forEach((testCase) => {
      initialCheckedRows[testCase.id] = {};
      Object.keys(testCase)
        .filter((key) => key.startsWith('testdata_'))
        .forEach((envKey) => {
          initialCheckedRows[testCase.id][envKey] = testCase[envKey].map(
            (_, index) => index
          );
        });
    });
    return initialCheckedRows;
  });

  const globalFields = useSelector(
    (state) => state.hosting.globalFields.fields
  );
  const dispatch = useDispatch();
  const globalFieldsKeys = globalFields.map((field) => field.key);
  const testDataEnvKey = `testdata_${environment.toLowerCase()}`;

  const [runAsSingleTransaction, setRunAsSingleTransaction] = useState(() => {
    const initialRunAsSingleTransaction = {};
    elements.forEach((testCase) => {
      const testCaseId = testCase.id;
      initialRunAsSingleTransaction[testCaseId] = {};
      Object.keys(testCase)
        .filter((key) => key.startsWith('run_as_single_transaction_'))
        .forEach((runAsSingleTransactionEnv) => {
          const envKey = runAsSingleTransactionEnv
            .replace('run_as_single_transaction_', '')
            .toUpperCase();
          initialRunAsSingleTransaction[testCaseId][envKey] =
            testCase[runAsSingleTransactionEnv];
        });
    });
    return initialRunAsSingleTransaction;
  });

  useEffect(() => {
    // scan the testData_ENV rows for files in the testdatafile column
    // and set the runAsSingleTransaction flag
    // to false if any file is found in the row of the testcase using its id
    const newRunAsSingleTransaction = { ...runAsSingleTransaction };

    testDataState.forEach((testCase) => {
      const testCaseId = testCase.id;
      const envKey = `testdata_${environment.toLowerCase()}`;
      const runAsSingleTransactionKey = `run_as_single_transaction_${environment.toLowerCase()}`;

      // Check if run_as_single_transaction_{ENV} key exists
      // and use its value to set the runAsSingleTransaction flag
      if (testCase[runAsSingleTransactionKey] !== undefined) {
        newRunAsSingleTransaction[testCaseId] =
          newRunAsSingleTransaction[testCaseId] || {};
        newRunAsSingleTransaction[testCaseId][environment] =
          testCase[runAsSingleTransactionKey];
      } else {
        // If the key does not exist, proceed with the test data file logic
        const hasFile = testCase[envKey].some((row) => row.testdatafile);
        newRunAsSingleTransaction[testCaseId] =
          newRunAsSingleTransaction[testCaseId] || {};
        newRunAsSingleTransaction[testCaseId][environment] = !hasFile;
      }
    });

    setRunAsSingleTransaction(newRunAsSingleTransaction);
  }, [environment, testDataState]);

  const handleRunAsSingleTransactionChange = (testCaseId, value) => {
    const newRunAsSingleTransaction = { ...runAsSingleTransaction };
    newRunAsSingleTransaction[testCaseId] =
      newRunAsSingleTransaction[testCaseId] || {};
    newRunAsSingleTransaction[testCaseId][environment] = value;
    setRunAsSingleTransaction((prevState) => ({
      ...prevState,
      ...newRunAsSingleTransaction,
    }));
  };

  // Extract unique tags from test cases
  const uniqueTags = [
    ...new Set(
      testDataState
        .flatMap((testCase) =>
          testCase[testDataEnvKey].flatMap((item) => item._tags.split(/[, ]+/))
        )
        .filter((tag) => tag)
    ),
  ].map((tag) => ({ value: tag, label: tag }));

  // remove tableData property
  const removeTableData = (data) => {
    return data.map(({ tableData, ...rest }) => rest);
  };

  // Handle input change
  const handleInputChange = (elementId, env, key, value, rowIndex) => {
    const envKey = `testdata_${env.toLowerCase()}`;
    const updatedData = filteredTestDataState.map((item) => {
      if (item.id === elementId) {
        const newData = [...item[envKey]];
        newData[rowIndex][key] = value;
        return { ...item, [envKey]: newData };
      }
      return item;
    });
    setFilteredTestDataState(updatedData);
    setTestDataState(updatedData);
  };

  const handleAddRow = (elementId, env, rowData) => {
    const envKey = `testdata_${env.toLowerCase()}`;
    const updatedData = filteredTestDataState.map((item) => {
      if (item.id === elementId) {
        const newRow = { ...rowData };
        delete newRow.tableData; // Remove tableData property
        return {
          ...item,
          [envKey]: [...item[envKey], newRow],
        };
      }
      return item;
    });
    setFilteredTestDataState(updatedData);
    setTestDataState(updatedData);
    setCheckedRows((prevCheckedRows) => {
      const newCheckedRows = { ...prevCheckedRows };
      if (!newCheckedRows[elementId]) {
        newCheckedRows[elementId] = {};
      }
      if (!newCheckedRows[elementId][envKey]) {
        newCheckedRows[elementId][envKey] = [];
      }
      const testCaseIndex = updatedData.findIndex(
        (testCase) => testCase.id === elementId
      );
      newCheckedRows[elementId][envKey].push(
        updatedData[testCaseIndex][envKey].length - 1
      );
      return newCheckedRows;
    });
  };

  const handleRemoveRow = (elementId, env, rowIndex) => {
    const envKey = `testdata_${env.toLowerCase()}`;
    const updatedData = filteredTestDataState.map((item) => {
      if (item.id === elementId) {
        const newData = [...item[envKey]];
        newData.splice(rowIndex, 1);
        return { ...item, [envKey]: newData };
      }
      return item;
    });
    setFilteredTestDataState(updatedData);
    setTestDataState(updatedData);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (inputRef.current) {
        inputRef.current.inputRef.blur();
      }
    }
  };

  const handleConfirmClose = () => {
    setShowConfirmClose(false);
    onClose();
  };

  const handleCancelClose = () => {
    setShowConfirmClose(false);
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: 'calc(1.5em + .75rem + 2px)',
      height: 'calc(1.5em + .75rem + 2px)', // Match the input height
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: 'calc(1.5em + .75rem + 2px)',
      padding: '0 8px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: 'calc(1.5em + .75rem + 2px)',
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 999999, // Ensure the menu is rendered on top of the modal
    }),
  };

  const handleTagFilterChange = (selectedOptions) => {
    setSelectedTags(selectedOptions || []);
    const selectedTagValues = (selectedOptions || []).map(
      (option) => option.value
    );
    if (selectedTagValues.length === 0) {
      setFilteredTestDataState(testDataState);
    } else {
      const filteredData = testDataState
        .map((testCase) => {
          const filteredRows = testCase[testDataEnvKey].filter((item) =>
            selectedTagValues.some((tag) => item._tags.includes(tag))
          );

          if (filteredRows.length > 0) {
            return { ...testCase, [testDataEnvKey]: filteredRows };
          }
          return null;
        })
        .filter(Boolean);

      setFilteredTestDataState(filteredData);
    }
  };

  const handleCheckboxChange = (testCaseId, env, rowIndex) => {
    setCheckedRows((prevCheckedRows) => {
      const newCheckedRows = { ...prevCheckedRows };
      if (!newCheckedRows[testCaseId]) {
        newCheckedRows[testCaseId] = {};
      }
      if (!newCheckedRows[testCaseId][env]) {
        newCheckedRows[testCaseId][env] = [];
      }
      if (newCheckedRows[testCaseId][env].includes(rowIndex)) {
        newCheckedRows[testCaseId][env] = newCheckedRows[testCaseId][
          env
        ].filter((index) => index !== rowIndex);
      } else {
        newCheckedRows[testCaseId][env].push(rowIndex);
      }
      return newCheckedRows;
    });
  };

  const isRowChecked = (testCaseId, env, rowIndex) => {
    return checkedRows[testCaseId]?.[env]?.includes(rowIndex) || false;
  };

  const renderFileCell = (rowData, key) => {
    if (rowData[key]) {
      const fileName =
        rowData[key] instanceof File
          ? rowData[key].name
          : rowData[key].split('/').pop();
      return (
        <a
          href={rowData[key]}
          download
          onClick={(e) => {
            e.preventDefault();
            downloadFile.fromURL(e.target.href);
          }}
        >
          {fileName}
        </a>
      );
    }
    return <span className="text-muted">No file uploaded</span>;
  };

  const renderIterationFileLabel = (testCaseId, env) => {
    const envKey = `testdata_${env.toLowerCase()}`;
    const file = iterationFiles[testCaseId]?.[envKey];

    if (!file) {
      return <span className="text-muted">No file uploaded</span>;
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <a
          href={file}
          download
          onClick={(e) => {
            e.preventDefault();
            downloadFile.fromURL(e.target.href);
          }}
        >
          {file.split('/').pop()}
        </a>
      </div>
    );
  };

  const renderTestIterationFileSection = (testCaseId, env) => {
    return (
      <div className="test-iteration-file-section">
        <div className="test-data-file-section">
          <span className="text-body">Iteration file: </span>{' '}
          {renderIterationFileLabel(testCaseId, env)}
        </div>
      </div>
    );
  };
  const renderTestDataSection = (element, env) => {
    const testDataEnvkey = `testdata_${env.toLowerCase()}`;
    const currentRowsCount = element[testDataEnvkey].length;

    return (
      <div style={{ overflowX: 'auto' }}>
        {buildTool === BUILD_TOOLS.MAVEN &&
          renderTestIterationFileSection(element.id, env)}
        {buildTool === BUILD_TOOLS.PLAYWRIGHT && (
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id={`runAsSingleTransaction-${element.id}`}
              checked={runAsSingleTransaction[element.id]?.[env] || false}
              onChange={(e) =>
                handleRunAsSingleTransactionChange(element.id, e.target.checked)
              }
              disabled={element[testDataEnvkey].some((row) => row.testdatafile)}
            />
            <label
              className="form-check-label"
              htmlFor={`runAsSingleTransaction-${element.id}`}
            >
              Run as single transaction
            </label>
          </div>
        )}
        <MaterialTable
          title={null}
          columns={[
            {
              title: 'Select',
              field: 'select',
              render: (rowData) => {
                const rowIndex = rowData.tableData.id;
                const checked = isRowChecked(
                  element.id,
                  testDataEnvkey,
                  rowIndex
                );
                return (
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={(e) =>
                      handleCheckboxChange(element.id, testDataEnvkey, rowIndex)
                    }
                  />
                );
              },
              cellStyle: { maxWidth: '50px' },
              headerStyle: {
                maxWidth: '50px',
                fontSize: '1rem',
                fontWeight: 'bold',
              },
            },
            {
              title: 'name',
              field: '_name',
              editable: 'never',
              cellStyle: {
                maxWidth: '10ch',
                minWidth: '150px',
              },
              headerStyle: {
                maxWidth: '10ch',
                minWidth: '150px',
                fontSize: '1rem',
                fontWeight: 'bold',
              },
              render: (rowData) => (
                <input
                  type="text"
                  value={rowData._name}
                  readOnly
                  className="form-control"
                />
              ),
            },
            {
              title: 'tags',
              field: '_tags',
              editable: 'never',
              cellStyle: {
                maxWidth: '10ch',
                minWidth: '150px',
              },
              headerStyle: {
                maxWidth: '10ch',
                minWidth: '150px',
                fontSize: '1rem',
                fontWeight: 'bold',
              },
              render: (rowData) => (
                <input
                  type="text"
                  value={rowData._tags}
                  readOnly
                  className="form-control"
                />
              ),
            },
            ...element.columnsOrder[testDataEnvkey]
              .filter((key) => key !== '_name' && key !== '_tags')
              .filter((key) => key !== 'testiterationfile')
              .map((key) => ({
                title: (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <span className="column-title">{key}</span>
                  </div>
                ),
                field: key,
                render: (rowData) => {
                  const rowIndex = rowData.tableData.id;
                  if (globalFieldsKeys.includes(key)) {
                    return (
                      <Select
                        value={{
                          value: rowData[key],
                          label: rowData[key],
                        }}
                        onChange={(option) => {
                          handleInputChange(
                            element.id,
                            env,
                            key,
                            option.value,
                            rowIndex
                          );
                        }}
                        inputRef={inputRef}
                        options={globalFields
                          .find((field) => field.key === key)
                          .options.map((opt) => ({
                            value: opt.value,
                            label: opt.value,
                          }))}
                        onInputChange={(inputValue, { action }) => {
                          if (
                            action === 'input-change' &&
                            !globalFields
                              .find((field) => field.key === key)
                              .options.some((opt) => opt.value === inputValue)
                          ) {
                            handleInputChange(
                              element.id,
                              env,
                              key,
                              inputValue,
                              rowIndex
                            );
                          }
                        }}
                        onBlur={(event) => {
                          if (!event.target.value) {
                            return;
                          }
                          if (
                            !globalFields
                              .find((field) => field.key === key)
                              .options.some(
                                (opt) => opt.value === event.target.value
                              )
                          ) {
                            handleInputChange(
                              element.id,
                              env,
                              key,
                              event.target.value,
                              rowIndex
                            );
                          }
                        }}
                        onKeyDown={handleKeyDown}
                        styles={customSelectStyles}
                        menuPortalTarget={document.body}
                        menuPosition="fixed"
                      />
                    );
                  }
                  if (key === 'testdatafile') {
                    return renderFileCell(rowData, key);
                  }
                  return (
                    <input
                      type="text"
                      value={rowData[key]}
                      onChange={(e) => {
                        handleInputChange(
                          element.id,
                          env,
                          key,
                          e.target.value,
                          rowIndex
                        );
                      }}
                      className="form-control"
                    />
                  );
                },
                cellStyle: {
                  maxWidth: `${key.length}ch`,
                  minWidth: '150px',
                },
                headerStyle: {
                  maxWidth: `${key.length}ch`,
                  minWidth: '150px',
                },
              })),
            {
              title: 'Actions',
              field: 'actions',
              render: (rowData) => (
                <div>
                  <ReactTooltip id="add-row" place="top" effect="solid">
                    <span>Add row</span>
                  </ReactTooltip>
                  <IconButton
                    onClick={() => handleAddRow(element.id, env, rowData)}
                    size="small"
                    data-tip
                    data-for="add-row"
                    disabled={currentRowsCount >= MAX_ROWS}
                    className={
                      currentRowsCount >= MAX_ROWS ? 'icon-button-disabled' : ''
                    }
                  >
                    <AddBoxIcon />
                  </IconButton>
                  <ReactTooltip id="remove-row" place="top" effect="solid">
                    <span>Remove row</span>
                  </ReactTooltip>
                  <IconButton
                    onClick={() =>
                      handleRemoveRow(element.id, env, rowData.tableData.id)
                    }
                    size="small"
                    data-tip
                    data-for="remove-row"
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              ),
              cellStyle: { minWidth: '150px' },
              headerStyle: {
                minWidth: '150px',
                fontSize: '1rem',
                fontWeight: 'bold',
              },
            },
          ]}
          data={removeTableData(element[testDataEnvkey])}
          options={{
            paging: false,
            search: false,
            actionsColumnIndex: 0,
            sorting: false,
            toolbar: false,
          }}
        />
      </div>
    );
  };

  const extractUploadedFiles = (testDataState) => {
    const uploadedFiles = {};
    const testDataEnvKey = `testdata_${environment.toLowerCase()}`;
    testDataState.forEach((testCase) => {
      const testCaseId = testCase.id;
      uploadedFiles[testCaseId] = {};

      uploadedFiles[testCaseId][testDataEnvKey] = {};
      testCase[testDataEnvKey].forEach((row, rowIndex) => {
        if (row.testdatafile) {
          uploadedFiles[testCaseId][testDataEnvKey][rowIndex] = {
            testdatafile: row.testdatafile,
          };
        }
      });
      // include test iteration files from iterationFiles state
      //  in uploaded files
      if (buildTool === BUILD_TOOLS.MAVEN) {
        uploadedFiles[testCaseId][testDataEnvKey]['testiterationfile'] = {
          testiterationfile: iterationFiles[testCaseId][testDataEnvKey],
        };
      }
    });

    return uploadedFiles;
  };

  const submitTestCases = async () => {
    const payload = filteredTestDataState.reduce((acc, testCase) => {
      const testData = Object.keys(testCase)
        .filter((key) => key.startsWith('testdata_'))
        .reduce((envAcc, env) => {
          envAcc[env] = testCase[env].filter((_, rowIndex) =>
            checkedRows[testCase.id]?.[env]?.includes(rowIndex)
          );
          return envAcc;
        }, {});
      acc[testCase.id] = {
        displayname: testCase.displayname,
        testcasename: testCase.testcasename,
        description: testCase.description,
        os: testCase.os,
        testcasedir: testCase.testcasedir,
        parameters: testCase.parameters,
        notsupportedenv: testCase.notsupportedenv,
        wiki: testCase.wiki,
        lineitems: testCase.lineitems,
        runAsSingleTransaction:
          runAsSingleTransaction[testCase.id][environment],
        ...testData,
      };
      return acc;
    }, {});

    const uploadedFiles = extractUploadedFiles(filteredTestDataState);

    setLoading(true);
    try {
      const response = await onSubmit(payload, uploadedFiles);
      dispatch(addNewNotification(response.data.message, 'success'));
    } catch (error) {
      if (error.response) {
        let detail = error.response.data.detail;
        if (Array.isArray(detail)) {
          detail = detail.map((d) => `${d.testcasename}: ${d.message}`);
        }
        dispatch(addNewNotification(detail, 'danger'));
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      onClose={() => setShowConfirmClose(true)}
      onSubmit={() => submitTestCases()}
      submitButtonText="Submit"
      title="Submit test case"
      submitEnabled={true}
      loading={loading}
      wide={true}
      shouldCloseOnOverlayClick={false} // Prevent closing on outside click
    >
      <div className="manage-test-data-modal">
        <React.Fragment>
          <div className="modal-form-common-fields">
            <div className="row">{commonFields}</div>
          </div>
          <div className="tag-filter">
            <Select
              isMulti
              options={uniqueTags}
              value={selectedTags}
              onChange={handleTagFilterChange}
              placeholder="Filter by tags..."
              isClearable
              styles={customSelectStyles}
              menuPortalTarget={document.body}
              menuPosition="fixed"
            />
          </div>
        </React.Fragment>
        <div>
          {filteredTestDataState.length > 1 ? (
            filteredTestDataState.map((element) => (
              <CollapsibleContent key={element.id} title={element.displayname}>
                {renderTestDataSection(element, environment)}
              </CollapsibleContent>
            ))
          ) : (
            <div style={{ overflowX: 'auto' }}>
              {renderTestDataSection(filteredTestDataState[0], environment)}
            </div>
          )}
        </div>
      </div>
      {showConfirmClose && (
        <div className="confirm-close-dialog">
          <p>Are you sure you want to close the modal?</p>
          <button onClick={handleConfirmClose} className="btn btn-primary">
            Yes
          </button>
          <button onClick={handleCancelClose} className="btn btn-secondary">
            No
          </button>
        </div>
      )}
    </Modal>
  );
};

export default SubmitTestCaseModal;
