import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Modal from 'components/Modal';
import MaterialTable from 'material-table';
import Select from 'react-select';
import CollapsibleContent from 'components/CollapsibleContent';
import IconButton from '@material-ui/core/IconButton';
import ReactTooltip from 'react-tooltip';
import {
  Edit as EditIcon,
  Check as CheckIcon,
  Delete as DeleteIcon,
  ViewColumn as ViewColumnIcon,
  AddBox as AddBoxIcon,
  Undo as UndoIcon,
  CloudUpload as CloudUploadIcon,
  Close as CloseIcon,
} from '@material-ui/icons';
import { addNewNotification } from 'store/actions/notification_actions';
import downloadFile from 'utils/downloadFile';
import { BUILD_TOOLS } from 'utils/consts';

const ManageTestDataModal = ({
  elements,
  onClose,
  onSubmit,
  title,
  parent: { tools: buildTool, envlist },
}) => {
  const MAX_COLUMNS = 20;
  const MAX_ROWS = 50;

  const [iterationFiles, setIterationFiles] = useState(() => {
    const files = {};
    elements.forEach((testCase) => {
      const testCaseId = testCase.id;
      files[testCaseId] = {};
      Object.keys(testCase)
        .filter((key) => key.startsWith('testdata_'))
        .forEach((envKey) => {
          const iterationFile = testCase[envKey].find(
            (row) => row.testiterationfile
          );
          if (iterationFile) {
            files[testCaseId][envKey] = iterationFile.testiterationfile;
          }
        });
    });
    return files;
  });

  const [testDataState, setTestDataState] = useState(() => {
    return elements.map((testCase) => {
      const updatedTestCase = { ...testCase };
      const allEnvs = [
        ...new Set([
          ...Object.keys(testCase),
          ...envlist.map(({ env }) => `testdata_${env.toLowerCase()}`),
        ]),
      ];
      allEnvs
        .filter((key) => key.startsWith('testdata_'))
        .forEach((envKey) => {
          if (!updatedTestCase[envKey]) {
            updatedTestCase[envKey] = [{ _tags: '', _name: '' }];
            updatedTestCase.columnsOrder = {
              ...updatedTestCase.columnsOrder,
              [envKey]: [],
            };
          } else {
            updatedTestCase[envKey] = updatedTestCase[envKey].map((row) => {
              if (row.testiterationfile) {
                const { testiterationfile, ...rest } = row;
                return rest;
              }
              return row;
            });
          }
        });
      return updatedTestCase;
    });
  });
  const [runAsSingleTransaction, setRunAsSingleTransaction] = useState(() => {
    const initialRunAsSingleTransaction = {};
    elements.forEach((testCase) => {
      const testCaseId = testCase.id;
      initialRunAsSingleTransaction[testCaseId] = {};
      Object.keys(testCase)
        .filter((key) => key.startsWith('run_as_single_transaction_'))
        .forEach((runAsSingleTransactionEnv) => {
          const envKey = runAsSingleTransactionEnv
            .replace('run_as_single_transaction_', '')
            .toUpperCase();
          initialRunAsSingleTransaction[testCaseId][envKey] =
            testCase[runAsSingleTransactionEnv];
        });
    });
    return initialRunAsSingleTransaction;
  });

  const [columnBeingEdited, setColumnBeingEdited] = useState({
    key: '',
    elementId: '',
  });
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [previousColumnTitle, setPreviousColumnTitle] = useState('');
  const [showConfirmClose, setShowConfirmClose] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [columnTitleErrors, setColumnTitleErrors] = useState({});
  const globalFields = useSelector(
    (state) => state.hosting.globalFields.fields
  );

  const dispatch = useDispatch();
  const globalFieldsKeys = globalFields.map((field) => field.key);

  // Extract environment keys
  const envKeys = [
    ...new Set([
      ...Object.keys(elements[0] || {})
        .filter((key) => key.startsWith('testdata_'))
        .map((key) => key.replace('testdata_', '').toUpperCase()),
      ...envlist.map(({ env }) => env),
    ]),
  ];

  const [currentEnv, setCurrentEnv] = useState(envKeys[0]);

  useEffect(() => {
    // scan the testData_ENV rows for files in the testdatafile column
    // and set the runAsSingleTransaction flag
    // to false if any file is found in the row of the testcase using its id
    const newRunAsSingleTransaction = { ...runAsSingleTransaction };

    testDataState.forEach((testCase) => {
      const testCaseId = testCase.id;
      const envKey = `testdata_${currentEnv.toLowerCase()}`;
      const runAsSingleTransactionKey = `run_as_single_transaction_${currentEnv.toLowerCase()}`;

      // Check if run_as_single_transaction_{ENV} key exists
      // and use its value to set the runAsSingleTransaction flag
      if (testCase[runAsSingleTransactionKey] !== undefined) {
        newRunAsSingleTransaction[testCaseId] =
          newRunAsSingleTransaction[testCaseId] || {};
        newRunAsSingleTransaction[testCaseId][currentEnv] =
          testCase[runAsSingleTransactionKey];
      } else {
        // If the key does not exist, proceed with the test data file logic
        const hasFile = testCase[envKey].some((row) => row.testdatafile);
        newRunAsSingleTransaction[testCaseId] =
          newRunAsSingleTransaction[testCaseId] || {};
        newRunAsSingleTransaction[testCaseId][currentEnv] = !hasFile;
      }
    });

    setRunAsSingleTransaction(newRunAsSingleTransaction);
  }, [currentEnv, testDataState]);

  const validateRequiredFields = () => {
    const errors = {};
    testDataState.forEach((testCase) => {
      const testCaseId = testCase.id;
      Object.keys(testCase)
        .filter((key) => key.startsWith('testdata_'))
        .forEach((envKey) => {
          // skip validation for empty testdata_envKey
          if (hasOnlyNameAndTags(testCase, envKey)) {
            return;
          }
          testCase[envKey].forEach((row, rowIndex) => {
            if (!row._name || row._name.trim() === '') {
              errors[testCaseId] = errors[testCaseId] || {};
              errors[testCaseId][envKey] = errors[testCaseId][envKey] || {};
              errors[testCaseId][envKey][rowIndex] =
                errors[testCaseId][envKey][rowIndex] || {};
              errors[testCaseId][envKey][rowIndex].requiredFields =
                'Required field is missing';
            }
          });
        });
    });
    setValidationErrors(errors);
  };

  useEffect(() => {
    validateRequiredFields();
  }, []);

  const handleRunAsSingleTransactionChange = (testCaseId, env, value) => {
    const newRunAsSingleTransaction = { ...runAsSingleTransaction };
    newRunAsSingleTransaction[testCaseId] =
      newRunAsSingleTransaction[testCaseId] || {};
    newRunAsSingleTransaction[testCaseId][env] = value;
    setRunAsSingleTransaction((prevState) => ({
      ...prevState,
      ...newRunAsSingleTransaction,
    }));
  };

  // remove tableData property
  const removeTableData = (data) => {
    return data.map(({ tableData, ...rest }) => rest);
  };

  const hasOnlyNameAndTags = (element, envKey) =>
    Object.keys(element[envKey][0]).every(
      (key) => key === '_name' || key === '_tags'
    );

  const copyDataFromExistingEnv = (elementId, toEnv) => {
    const toEnvKey = `testdata_${toEnv.toLowerCase()}`;
    let dataCopied = false;
    const updatedData = testDataState.map((item) => {
      if (item.id === elementId) {
        const fromEnvKey = Object.keys(item).find(
          (key) =>
            key.startsWith('testdata_') &&
            key !== toEnvKey &&
            !hasOnlyNameAndTags(item, key)
        );

        if (fromEnvKey) {
          dataCopied = true;
          return {
            ...item,
            columnsOrder: {
              ...item.columnsOrder,
              [toEnvKey]: item.columnsOrder[fromEnvKey],
            },
            [toEnvKey]: item[fromEnvKey].map((row) => {
              const newRow = { ...row };
              delete newRow.tableData; // Remove tableData property
              return newRow;
            }),
          };
        }
      }
      return item;
    });
    setTestDataState(updatedData);
    validateFields(elementId, toEnvKey, updatedData);

    if (!dataCopied) {
      handleAddColumn(toEnv, elementId);
    }
  };

  // Handle input change
  const handleInputChange = (elementId, env, key, value, rowIndex) => {
    const envKey = `testdata_${env.toLowerCase()}`;
    const updatedData = testDataState.map((item) => {
      if (item.id === elementId) {
        const newData = [...item[envKey]];
        newData[rowIndex][key] = value;
        return { ...item, [envKey]: newData };
      }
      return item;
    });
    setTestDataState(updatedData);
    validateFields(elementId, envKey, updatedData);
  };

  const validateFields = (elementId, envKey, updatedData) => {
    const errors = { ...validationErrors };
    // Validate unique names
    const testCase = updatedData.find((item) => item.id === elementId);
    const names = testCase[envKey].map((row) => row._name.toLowerCase());
    const duplicateNames = names.filter(
      (name, index) => names.indexOf(name) !== index
    );

    testCase[envKey].forEach((row, rowIndex) => {
      if (duplicateNames.includes(row._name.toLowerCase())) {
        errors[elementId] = errors[elementId] || {};
        errors[elementId][envKey] = errors[elementId][envKey] || {};
        errors[elementId][envKey][rowIndex] =
          errors[elementId][envKey][rowIndex] || {};
        errors[elementId][envKey][rowIndex].uniqueName =
          'Duplicate names found';
      } else {
        if (
          errors[elementId] &&
          errors[elementId][envKey] &&
          errors[elementId][envKey][rowIndex]
        ) {
          delete errors[elementId][envKey][rowIndex].uniqueName;
          if (Object.keys(errors[elementId][envKey][rowIndex]).length === 0) {
            delete errors[elementId][envKey][rowIndex];
          }
          if (Object.keys(errors[elementId][envKey]).length === 0) {
            delete errors[elementId][envKey];
          }
          if (Object.keys(errors[elementId]).length === 0) {
            delete errors[elementId];
          }
        }
      }
    });

    // Validate required fields
    const requiredFields = ['_name'];
    testCase[envKey].forEach((row, rowIndex) => {
      const missingFields = requiredFields.filter(
        (field) => !row[field] || row[field].trim() === ''
      );

      if (missingFields.length > 0) {
        errors[elementId] = errors[elementId] || {};
        errors[elementId][envKey] = errors[elementId][envKey] || {};
        errors[elementId][envKey][rowIndex] =
          errors[elementId][envKey][rowIndex] || {};
        errors[elementId][envKey][rowIndex].requiredFields =
          'Required field is missing';
      } else {
        if (
          errors[elementId] &&
          errors[elementId][envKey] &&
          errors[elementId][envKey][rowIndex]
        ) {
          delete errors[elementId][envKey][rowIndex].requiredFields;
          if (Object.keys(errors[elementId][envKey][rowIndex]).length === 0) {
            delete errors[elementId][envKey][rowIndex];
          }
          if (Object.keys(errors[elementId][envKey]).length === 0) {
            delete errors[elementId][envKey];
          }
          if (Object.keys(errors[elementId]).length === 0) {
            delete errors[elementId];
          }
        }
      }
    });

    // clean errors if row has only name and tags
    if (hasOnlyNameAndTags(testCase, envKey)) {
      delete errors[elementId];
    }

    setValidationErrors(errors);
  };

  const handleAddRow = (elementId, env, rowData) => {
    const envKey = `testdata_${env.toLowerCase()}`;
    const updatedData = testDataState.map((item) => {
      if (item.id === elementId) {
        const newRow = { ...rowData, testdatafile: '', _name: '' };
        delete newRow.tableData; // Remove tableData property
        return {
          ...item,
          [envKey]: [...item[envKey], newRow],
        };
      }
      return item;
    });
    setTestDataState(updatedData);
    validateFields(elementId, envKey, updatedData);
  };

  const handleRemoveRow = async (elementId, env, rowIndex) => {
    const envKey = `testdata_${env.toLowerCase()}`;
    const fileUrl = testDataState.find((item) => item.id === elementId)[envKey][
      rowIndex
    ].testdatafile;

    if (fileUrl) {
      await handleFileDelete(elementId, env, rowIndex);
    }

    const updatedData = testDataState.map((item) => {
      if (item.id === elementId) {
        const newData = [...item[envKey]];
        newData.splice(rowIndex, 1);
        // if this is the last element in the array,
        // reset it to default{_tags: '', _name: ''}
        // being _tags: '' as it's expected to be [] only in the backend
        if (newData.length === 0) {
          newData.push({ _name: '', _tags: '' });
        }
        return { ...item, [envKey]: newData };
      }
      return item;
    });
    setTestDataState(updatedData);
    validateFields(elementId, envKey, updatedData);
  };

  const handleColumnTitleChange = (elementId, env, oldKey, newKey) => {
    const envKey = `testdata_${env.toLowerCase()}`;
    const updatedData = testDataState.map((item) => {
      if (item.id === elementId) {
        const newData = item[envKey].map((row) => {
          const updatedRow = { ...row };
          if (oldKey !== newKey) {
            updatedRow[newKey] = updatedRow[oldKey];
            delete updatedRow[oldKey];
          }
          return updatedRow;
        });
        const updatedColumnsOrder = item.columnsOrder[envKey].map((key) =>
          key === oldKey ? newKey : key
        );
        return {
          ...item,
          [envKey]: newData,
          columnsOrder: {
            ...item.columnsOrder,
            [envKey]: updatedColumnsOrder,
          },
        };
      }
      return item;
    });
    setColumnBeingEdited({
      key: '',
      elementId: '',
    });
    setTestDataState(updatedData);
  };

  const handleTitleInputChange = (elementId, env, oldKey, newKey) => {
    setInputValue(newKey);
    const envKey = `testdata_${env.toLowerCase()}`;
    const lowerCaseNewKey = newKey.toLowerCase();
    const lowerCaseColumnsOrder = testDataState
      .find((item) => item.id === elementId)
      .columnsOrder[envKey].map((key) => key.toLowerCase());

    if (
      [...lowerCaseColumnsOrder, 'name', 'tags', '_name', '_tags'].includes(
        lowerCaseNewKey
      ) &&
      oldKey.toLowerCase() !== lowerCaseNewKey
    ) {
      // Prevent adding columns with the same name
      setColumnTitleErrors((prevErrors) => ({
        ...prevErrors,
        [elementId]: {
          ...prevErrors[elementId],
          [envKey]: 'Column name already exists',
        },
      }));
    } else {
      setColumnTitleErrors((prevErrors) => {
        const { [envKey]: removed, ...rest } = prevErrors[elementId] || {};
        const cleanedErrors = {
          ...prevErrors,
          [elementId]: rest,
        };
        if (Object.keys(cleanedErrors[elementId]).length === 0) {
          delete cleanedErrors[elementId];
        }
        return cleanedErrors;
      });
    }
  };

  const handleUndoEditColumnTitle = (elementId, envKey) => {
    setInputValue(previousColumnTitle);
    setColumnBeingEdited({ key: '', elementId: '' });
    setPreviousColumnTitle('');
    if (
      columnTitleErrors[elementId] &&
      Object.keys(columnTitleErrors[elementId]).length > 0
    ) {
      setColumnTitleErrors((prevErrors) => {
        const { [envKey]: removed, ...rest } = prevErrors[elementId];
        const cleanedErrors = {
          ...prevErrors,
          [elementId]: rest,
        };
        if (Object.keys(cleanedErrors[elementId]).length === 0) {
          delete cleanedErrors[elementId];
        }
        return cleanedErrors;
      });
    }
  };

  const handleKeyDown = (event) => {
    // if (event.key === 'Escape') {
    //   handleUndoEditColumnTitle();
    // }
    if (event.key === 'Enter') {
      inputRef.current.inputRef.blur();
    }
  };

  const getNextColumnNumber = (columns) => {
    const columnNumbers = columns
      .filter((key) => key.startsWith('column'))
      .map((key) => parseInt(key.replace('column', ''), 10))
      .sort((a, b) => a - b);

    let nextNumber = 1;
    for (const number of columnNumbers) {
      if (number !== nextNumber) {
        break;
      }
      nextNumber++;
    }
    return nextNumber;
  };

  const handleAddColumn = (env, testCaseId) => {
    const envKey = `testdata_${env.toLowerCase()}`;
    const updatedData = testDataState.map((testCase) => {
      if (testCase.id !== testCaseId) {
        return testCase;
      }

      // Generate the next column name in the sequence
      const nextColumnNumber = getNextColumnNumber(
        testCase.columnsOrder[envKey]
      );
      const newColumnKey = `column${nextColumnNumber}`;

      const newData = testCase[envKey].map((row) => ({
        ...row,
        [newColumnKey]: '',
      }));

      return {
        ...testCase,
        [envKey]: newData,
        columnsOrder: {
          ...testCase.columnsOrder,
          [envKey]: [...testCase.columnsOrder[envKey], newColumnKey],
        },
      };
    });
    setTestDataState(updatedData);
    validateFields(testCaseId, envKey, updatedData);
  };

  const handleRemoveColumn = (key, testCaseId) => {
    const envKey = `testdata_${currentEnv.toLowerCase()}`;
    const updatedData = testDataState.map((testCase) => {
      if (testCase.id !== testCaseId) {
        return testCase;
      }
      const newData = {
        ...testCase,
        [envKey]: testCase[envKey].map((row) => {
          const { [key]: removed, ...rest } = row;
          return rest;
        }),
      };
      const newColumnsOrder = {
        ...testCase.columnsOrder,
        [envKey]: testCase.columnsOrder[envKey].filter(
          (columnKey) => columnKey !== key
        ),
      };
      return {
        ...testCase,
        ...newData,
        columnsOrder: newColumnsOrder,
      };
    });

    // Clean columnTitleErrors if the removed column had an error associated
    setColumnTitleErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      Object.keys(newErrors).forEach((elementId) => {
        if (
          newErrors[elementId] &&
          newErrors[elementId][envKey] === 'Column name already exists'
        ) {
          delete newErrors[elementId][envKey];
          if (Object.keys(newErrors[elementId]).length === 0) {
            delete newErrors[elementId];
          }
        }
      });
      return newErrors;
    });

    setTestDataState(updatedData);
  };

  // Handle iteration file upload
  const handleIterationFileChange = (event, testCaseId, env) => {
    const file = event.target.files[0];
    const envKey = `testdata_${env.toLowerCase()}`;
    setIterationFiles((prevFiles) => {
      const updatedFiles = { ...prevFiles };
      if (!updatedFiles[testCaseId]) {
        updatedFiles[testCaseId] = {};
      }
      updatedFiles[testCaseId][envKey] = file;
      return updatedFiles;
    });
  };

  // Handle test data file upload
  const handleTestDataFileChange = (event, elementId, env, rowIndex) => {
    const file = event.target.files[0];
    const envKey = `testdata_${env.toLowerCase()}`;
    const updatedData = testDataState.map((item) => {
      if (item.id === elementId) {
        const newData = [...item[envKey]].map((item) => ({
          ...item,
          testdatafile: !item.testdatafile ? '' : item.testdatafile,
        }));
        newData[rowIndex].testdatafile = file;
        const updatedColumnsOrder = item.columnsOrder[envKey].includes(
          'testdatafile'
        )
          ? item.columnsOrder[envKey]
          : [...item.columnsOrder[envKey], 'testdatafile'];
        return {
          ...item,
          [envKey]: newData,
          columnsOrder: {
            ...item.columnsOrder,
            [envKey]: updatedColumnsOrder,
          },
        };
      }
      return item;
    });
    setTestDataState(updatedData);
    const newRunAsSingleTransaction = { ...runAsSingleTransaction };
    newRunAsSingleTransaction[elementId] =
      newRunAsSingleTransaction[elementId] || {};
    newRunAsSingleTransaction[elementId][env] = false;
    setRunAsSingleTransaction((prevState) => ({
      ...prevState,
      ...newRunAsSingleTransaction,
    }));
  };

  const createPayload = (testDataState) => {
    const payload = testDataState.reduce((acc, testCase) => {
      // if build tool is playwright
      // add run_as_single_transaction flag to the payload
      let runAsSingleTransactionEnv = {};
      if (buildTool === BUILD_TOOLS.PLAYWRIGHT) {
        runAsSingleTransactionEnv = Object.keys(
          runAsSingleTransaction[testCase.id] || {}
        ).reduce((envAcc, env) => {
          envAcc[`run_as_single_transaction_${env.toLowerCase()}`] =
            runAsSingleTransaction[testCase.id][env];
          return envAcc;
        }, {});
      }

      acc[testCase.id] = {
        displayname: testCase.displayname,
        testcasename: testCase.testcasename,
        description: testCase.description,
        os: testCase.os,
        testcasedir: testCase.testcasedir,
        parameters: testCase.parameters,
        notsupportedenv: testCase.notsupportedenv,
        wiki: testCase.wiki,
        lineitems: testCase.lineitems,
        ...transformTestData(testCase),
        ...runAsSingleTransactionEnv,
      };
      return acc;
    }, {});
    return payload;
  };

  const handleFileDelete = async (testCaseId, env, rowIndex) => {
    const envKey = `testdata_${env.toLowerCase()}`;
    const updatedData = testDataState.map((item) => {
      if (item.id === testCaseId) {
        const newData = [...item[envKey]];
        newData[rowIndex].testdatafile = '';
        return { ...item, [envKey]: newData };
      }
      return item;
    });
    setTestDataState(updatedData);
  };

  const handleClearFile = (elementId, env, rowIndex) => {
    const envKey = `testdata_${env.toLowerCase()}`;
    const updatedData = testDataState.map((item) => {
      if (item.id === elementId) {
        const newData = [...item[envKey]];
        newData[rowIndex].testdatafile = '';
        return { ...item, [envKey]: newData };
      }
      return item;
    });
    setTestDataState(updatedData);
  };

  const handleClearIterationFile = (testCaseId, env) => {
    const envKey = `testdata_${env.toLowerCase()}`;
    setIterationFiles((prevFiles) => {
      const updatedFiles = { ...prevFiles };
      if (!updatedFiles[testCaseId]) {
        updatedFiles[testCaseId] = {};
      }
      updatedFiles[testCaseId][envKey] = null;
      return updatedFiles;
    });
  };

  const renderFileCell = (rowData, key, testCaseId, env, rowIndex) => {
    if (rowData[key]) {
      const fileName =
        rowData[key] instanceof File
          ? rowData[key].name
          : rowData[key].split('/').pop();
      if (rowData[key] instanceof File) {
        return (
          <>
            <span>{fileName}</span>
            <IconButton
              onClick={() => handleClearFile(testCaseId, env, rowIndex)}
              size="small"
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        );
      }
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <a
            href={rowData[key]}
            download
            onClick={(e) => {
              e.preventDefault();
              downloadFile.fromURL(e.target.href);
            }}
          >
            {fileName}
          </a>
          <IconButton
            onClick={() => handleFileDelete(testCaseId, env, rowIndex)}
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      );
    }
    return <span className="text-muted">No file uploaded</span>;
  };
  const renderIterationFileLabel = (testCaseId, env) => {
    const envKey = `testdata_${env.toLowerCase()}`;
    const file = iterationFiles[testCaseId]?.[envKey];

    const fileName = file
      ? file instanceof File
        ? file.name
        : file.split('/').pop()
      : null;

    if (!file) {
      return <span className="text-muted">No file uploaded</span>;
    }

    if (file instanceof File) {
      return (
        <>
          <span>{fileName}</span>
          <IconButton
            onClick={() => {
              handleClearIterationFile(testCaseId, env);
            }}
            size="small"
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </>
      );
    }
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <a
          href={file}
          download
          onClick={(e) => {
            e.preventDefault();
            downloadFile.fromURL(e.target.href);
          }}
        >
          {fileName}
        </a>
        <IconButton
          onClick={() => {
            handleClearIterationFile(testCaseId, env);
          }}
          size="small"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </div>
    );
  };

  const renderTestIterationFileSection = (testCaseId, env) => {
    return (
      <div>
        <div className="test-data-file-section">
          <section className="file-upload-section">
            <label
              htmlFor={`fileUpload-${testCaseId}-${env}`}
              className="btn btn-primary choose-file-button"
            >
              Iteration file
            </label>
            <input
              type="file"
              accept=".xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              id={`fileUpload-${testCaseId}-${env}`}
              onChange={(e) => handleIterationFileChange(e, testCaseId, env)}
              style={{ display: 'none' }}
            />
          </section>{' '}
          {renderIterationFileLabel(testCaseId, env)}
        </div>
        <div className="warning-text">
          <small className="form-text text-muted">
            **Uploading a new file will overwrite the existing file for this
            test execution.
          </small>
        </div>
      </div>
    );
  };

  const handleConfirmClose = () => {
    setShowConfirmClose(false);
    onClose();
  };

  const handleCancelClose = () => {
    setShowConfirmClose(false);
  };

  const customSelectStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: 'calc(1.5em + .75rem + 2px)',
      height: 'calc(1.5em + .75rem + 2px)', // Match the input height
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: 'calc(1.5em + .75rem + 2px)',
      padding: '0 8px',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0',
      padding: '0',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: 'calc(1.5em + .75rem + 2px)',
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 999999, // Ensure the menu is rendered on top of the modal
    }),
  };

  const renderTestDataSection = (element, env) => {
    const envKey = `testdata_${env.toLowerCase()}`;
    const currentColumnsCount = element.columnsOrder[envKey].length;
    const currentRowsCount = element[envKey].length;

    return (
      <div>
        {buildTool === BUILD_TOOLS.MAVEN &&
          renderTestIterationFileSection(element.id, env)}
        {buildTool === BUILD_TOOLS.PLAYWRIGHT && (
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id={`runAsSingleTransaction-${element.id}-${env}`}
              checked={runAsSingleTransaction[element.id]?.[env] || false}
              onChange={(e) =>
                handleRunAsSingleTransactionChange(
                  element.id,
                  env,
                  e.target.checked
                )
              }
              disabled={element[envKey].some((row) => row.testdatafile)}
            />
            <label
              className="form-check-label"
              htmlFor={`runAsSingleTransaction-${element.id}-${env}`}
            >
              Run as single transaction
            </label>
          </div>
        )}
        {hasOnlyNameAndTags(element, envKey) ? (
          <button
            className="btn btn-primary"
            onClick={() => copyDataFromExistingEnv(element.id, env)}
          >
            Add Fields
          </button>
        ) : (
          <div style={{ overflowX: 'auto' }}>
            <MaterialTable
              title={null}
              columns={[
                {
                  title: 'name',
                  field: '_name',
                  editable: 'never',
                  cellStyle: {
                    maxWidth: '10ch',
                    minWidth: '150px',
                  },
                  headerStyle: {
                    maxWidth: '10ch',
                    minWidth: '150px',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                  },
                  render: (rowData) => (
                    <div>
                      <input
                        type="text"
                        value={rowData._name}
                        onChange={(e) =>
                          handleInputChange(
                            element.id,
                            env,
                            '_name',
                            e.target.value,
                            rowData.tableData.id
                          )
                        }
                        className={`form-control ${
                          validationErrors[element.id] &&
                          validationErrors[element.id][envKey] &&
                          validationErrors[element.id][envKey][
                            rowData.tableData.id
                          ]
                            ? 'is-invalid'
                            : ''
                        }`}
                      />
                      {validationErrors[element.id] &&
                        validationErrors[element.id][envKey] &&
                        validationErrors[element.id][envKey][
                          rowData.tableData.id
                        ] && (
                          <div className="invalid-feedback">
                            {
                              validationErrors[element.id][envKey][
                                rowData.tableData.id
                              ].uniqueName
                            }
                            {
                              validationErrors[element.id][envKey][
                                rowData.tableData.id
                              ].requiredFields
                            }
                          </div>
                        )}
                    </div>
                  ),
                },
                {
                  title: 'tags',
                  field: '_tags',
                  editable: 'never',
                  cellStyle: {
                    maxWidth: '10ch',
                    minWidth: '150px',
                  },
                  headerStyle: {
                    maxWidth: '10ch',
                    minWidth: '150px',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                  },
                  render: (rowData) => (
                    <input
                      type="text"
                      value={rowData._tags}
                      onChange={(e) =>
                        handleInputChange(
                          element.id,
                          env,
                          '_tags',
                          e.target.value,
                          rowData.tableData.id
                        )
                      }
                      className="form-control"
                    />
                  ),
                },
                ...element.columnsOrder[envKey]
                  .filter((key) => key !== '_name' && key !== '_tags')
                  .filter((key) => key !== 'testiterationfile')
                  .map((key) => ({
                    title: (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        {columnBeingEdited.key === key &&
                        columnBeingEdited.elementId === element.id ? (
                          <Select
                            value={
                              inputValue
                                ? {
                                    value: inputValue,
                                    label: inputValue,
                                  }
                                : null
                            }
                            options={globalFieldsKeys
                              .filter(
                                (fieldKey) =>
                                  !element.columnsOrder[envKey].includes(
                                    fieldKey
                                  )
                              )
                              .map((fieldKey) => ({
                                value: fieldKey,
                                label: fieldKey,
                              }))}
                            inputValue={inputValue}
                            ref={inputRef}
                            onChange={(option) => {
                              if (!option) {
                                return;
                              }
                              handleColumnTitleChange(
                                element.id,
                                env,
                                key,
                                option.value
                              );
                            }}
                            onInputChange={(inputValue, { action }) => {
                              if (
                                action === 'input-change' &&
                                !globalFieldsKeys.includes(inputValue)
                              ) {
                                handleTitleInputChange(
                                  element.id,
                                  env,
                                  key,
                                  inputValue
                                );
                              }
                            }}
                            onBlur={(event) => {
                              if (!event.target.value) {
                                return;
                              }
                              if (
                                !globalFieldsKeys.includes(event.target.value)
                              ) {
                                if (
                                  !columnTitleErrors[element.id] ||
                                  !columnTitleErrors[element.id][envKey]
                                ) {
                                  handleColumnTitleChange(
                                    element.id,
                                    env,
                                    key,
                                    event.target.value
                                  );
                                }
                              }
                            }}
                            onKeyDown={handleKeyDown}
                            styles={{
                              ...customSelectStyles,
                              container: (provided) => ({
                                ...provided,
                                overflow: 'visible',
                                minWidth: '150px',
                              }),
                            }}
                            menuPortalTarget={document.body} // Render the menu in a portal
                            menuPosition="fixed" // Position the menu fixed
                            autoFocus
                            noOptionsMessage={() => null}
                          />
                        ) : (
                          <span className="column-title">{key}</span>
                        )}
                        {columnBeingEdited.key === key &&
                        columnBeingEdited.elementId === element.id
                          ? [
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  marginLeft: '5px',
                                }}
                              >
                                <IconButton
                                  onClick={() => {
                                    handleColumnTitleChange(
                                      element.id,
                                      env,
                                      key,
                                      inputValue || key
                                    );
                                  }}
                                  size="small"
                                  disabled={
                                    columnTitleErrors[element.id] &&
                                    columnTitleErrors[element.id][envKey]
                                  }
                                >
                                  <CheckIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  onClick={() =>
                                    handleUndoEditColumnTitle(
                                      element.id,
                                      envKey
                                    )
                                  }
                                  size="small"
                                >
                                  <UndoIcon fontSize="small" />
                                </IconButton>
                                <IconButton
                                  onClick={() => {
                                    handleRemoveColumn(key, element.id);
                                    setColumnBeingEdited({
                                      key: '',
                                      elementId: '',
                                    });
                                  }}
                                  size="small"
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </div>,
                              <div className="column-invalid-feedback-block">
                                {columnTitleErrors[element.id] &&
                                  columnTitleErrors[element.id][envKey] && (
                                    <div className="invalid-feedback d-block">
                                      {columnTitleErrors[element.id][envKey]}
                                    </div>
                                  )}
                              </div>,
                            ]
                          : key !== 'testdatafile' && (
                              <IconButton
                                onClick={() => {
                                  setInputValue(key);
                                  setColumnBeingEdited({
                                    key: key,
                                    elementId: element.id,
                                  });
                                }}
                                size="small"
                                style={{ marginLeft: '5px' }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            )}
                      </div>
                    ),
                    field: key,
                    editable: key === 'testdatafile' ? 'never' : 'always',
                    render: (rowData) => {
                      const rowIndex = rowData.tableData.id;
                      if (globalFieldsKeys.includes(key)) {
                        return (
                          <Select
                            value={{
                              value: rowData[key],
                              label: rowData[key],
                            }}
                            onChange={(option) => {
                              handleInputChange(
                                element.id,
                                env,
                                key,
                                option.value,
                                rowIndex
                              );
                            }}
                            options={globalFields
                              .find((field) => field.key === key)
                              .options.map((opt) => ({
                                value: opt.value,
                                label: opt.value,
                              }))}
                            onInputChange={(inputValue, { action }) => {
                              if (
                                action === 'input-change' &&
                                !globalFields
                                  .find((field) => field.key === key)
                                  .options.some(
                                    (opt) => opt.value === inputValue
                                  )
                              ) {
                                handleInputChange(
                                  element.id,
                                  env,
                                  key,
                                  inputValue,
                                  rowIndex
                                );
                              }
                            }}
                            onBlur={(event) => {
                              if (!event.target.value) {
                                return;
                              }
                              if (
                                !globalFields
                                  .find((field) => field.key === key)
                                  .options.some(
                                    (opt) => opt.value === event.target.value
                                  )
                              ) {
                                handleInputChange(
                                  element.id,
                                  env,
                                  key,
                                  event.target.value,
                                  rowIndex
                                );
                              }
                            }}
                            styles={customSelectStyles}
                            menuPortalTarget={document.body} // Render the menu in a portal
                            menuPosition="fixed" // Position the menu fixed
                          />
                        );
                      }
                      if (key === 'testdatafile') {
                        return renderFileCell(
                          rowData,
                          key,
                          element.id,
                          env,
                          rowIndex
                        );
                      }
                      return (
                        <input
                          type="text"
                          value={rowData[key]}
                          onChange={(e) => {
                            handleInputChange(
                              element.id,
                              env,
                              key,
                              e.target.value,
                              rowIndex
                            );
                          }}
                          className="form-control"
                        />
                      );
                    },
                    cellStyle: {
                      maxWidth: `${key.length}ch`,
                      minWidth: '150px',
                    },
                    headerStyle: {
                      maxWidth: `${key.length}ch`,
                      minWidth: '150px',
                    },
                  })),
                {
                  title: 'Actions',
                  field: 'actions',
                  render: (rowData) => (
                    <div>
                      <ReactTooltip id="add-column" place="top" effect="solid">
                        <span>Add field</span>
                      </ReactTooltip>
                      <IconButton
                        onClick={() => handleAddColumn(env, element.id)}
                        size="small"
                        data-tip
                        data-for="add-column"
                        disabled={currentColumnsCount >= MAX_COLUMNS}
                        className={
                          currentColumnsCount >= MAX_COLUMNS
                            ? 'icon-button-disabled'
                            : ''
                        }
                      >
                        <ViewColumnIcon />
                      </IconButton>
                      <ReactTooltip id="add-row" place="top" effect="solid">
                        <span>Add row</span>
                      </ReactTooltip>
                      <IconButton
                        onClick={() => handleAddRow(element.id, env, rowData)}
                        size="small"
                        data-tip
                        data-for="add-row"
                        disabled={currentRowsCount >= MAX_ROWS}
                        className={
                          currentRowsCount >= MAX_ROWS
                            ? 'icon-button-disabled'
                            : ''
                        }
                      >
                        <AddBoxIcon />
                      </IconButton>
                      <ReactTooltip id="remove-row" place="top" effect="solid">
                        <span>Remove row</span>
                      </ReactTooltip>
                      <IconButton
                        onClick={() =>
                          handleRemoveRow(element.id, env, rowData.tableData.id)
                        }
                        // disabled={currentRowsCount <= 1}
                        size="small"
                        data-tip
                        data-for="remove-row"
                      >
                        <DeleteIcon />
                      </IconButton>
                      <ReactTooltip id="upload-file" place="top" effect="solid">
                        <span>Upload file</span>
                      </ReactTooltip>
                      <IconButton
                        onClick={() =>
                          document
                            .getElementById(
                              `fileUpload-${element.id}-${env}-${rowData.tableData.id}`
                            )
                            .click()
                        }
                        size="small"
                        data-tip
                        data-for="upload-file"
                      >
                        <CloudUploadIcon />
                      </IconButton>
                      <input
                        type="file"
                        id={`fileUpload-${element.id}-${env}-${rowData.tableData.id}`}
                        onChange={(e) =>
                          handleTestDataFileChange(
                            e,
                            element.id,
                            env,
                            rowData.tableData.id
                          )
                        }
                        style={{ display: 'none' }}
                      />
                    </div>
                  ),
                  cellStyle: { minWidth: '150px' },
                  headerStyle: {
                    minWidth: '150px',
                    fontSize: '1rem',
                    fontWeight: 'bold',
                  },
                },
              ]}
              data={removeTableData(element[envKey])}
              options={{
                paging: false,
                search: false,
                actionsColumnIndex: 0,
                sorting: false,
                toolbar: false,
              }}
            />
          </div>
        )}
      </div>
    );
  };

  const transformTestData = (testCase) => {
    return Object.keys(testCase)
      .filter((envKey) => envKey.startsWith('testdata_'))
      .reduce((acc, envKey) => {
        acc[envKey] = testCase[envKey].map((item) => {
          const transformedItem = {
            _tags: item._tags
              ? item._tags.split(/[, ]+/)
              : [] /* we send _tags as an array */,
            ...Object.keys(item)
              .filter((key) => key !== '_tags')
              .reduce((obj, key) => {
                obj[key] = item[key];
                return obj;
              }, {}),
          };
          return transformedItem;
        });
        if (
          iterationFiles[testCase.id] &&
          iterationFiles[testCase.id][envKey] &&
          !(iterationFiles[testCase.id][envKey] instanceof File) &&
          iterationFiles[testCase.id][envKey].startsWith('http')
        ) {
          // instead of pushing the file location url to the array,
          //  we add it as a key to the last row of the test data
          acc[envKey][acc[envKey].length - 1]['testiterationfile'] =
            iterationFiles[testCase.id][envKey];
        }
        return acc;
      }, {});
  };

  const extractUploadedFiles = (testDataState) => {
    const uploadedFiles = {};

    testDataState.forEach((testCase) => {
      const testCaseId = testCase.id;
      uploadedFiles[testCaseId] = {};

      Object.keys(testCase)
        .filter((envKey) => envKey.startsWith('testdata_'))
        .forEach((envKey) => {
          uploadedFiles[testCaseId][envKey] = {};

          testCase[envKey].forEach((row, rowIndex) => {
            uploadedFiles[testCaseId][envKey][rowIndex] = {
              testdatafile: row.testdatafile,
              _name: row._name,
            };
          });
          // include test iteration file in uploaded files
          if (buildTool === BUILD_TOOLS.MAVEN) {
            uploadedFiles[testCaseId][envKey]['testiterationfile'] = {
              testiterationfile: iterationFiles[testCaseId][envKey],
            };
          }
        });
    });

    return uploadedFiles;
  };

  const submitTestData = async () => {
    const payload = createPayload(testDataState);
    // uploadedFiles contains the files that were uploaded
    // for each test case testdata_ENV row
    // The files are stored in an object with the following structure:
    // {
    //   [testCaseId]: {
    //     [testdata_ENV]: {[rowIndex]: {[testdatafileType]: file}
    //   }
    // }
    // so upload requests can be chained in a promisse.all in Hosting.js
    const uploadedFiles = extractUploadedFiles(testDataState);

    setLoading(true);
    try {
      const response = await onSubmit(payload, uploadedFiles);
      dispatch(addNewNotification(response.data.message, 'success'));
    } catch (error) {
      if (error.response) {
        let detail = error.response.data.detail;
        if (Array.isArray(detail)) {
          detail = detail.map((d) => `${d.testcasename}: ${d.message}`);
        }
        dispatch(addNewNotification(detail, 'danger'));
      }
    } finally {
      setLoading(false);
    }
  };

  const hasValidationError = (envKey) => {
    return Object.values(validationErrors).some(
      (error) => error[envKey] && Object.keys(error[envKey]).length > 0
    );
  };

  return (
    <Modal
      onClose={() => setShowConfirmClose(true)}
      onSubmit={() => submitTestData()}
      submitButtonText="Save"
      title={title}
      submitEnabled={
        validationErrors &&
        Object.keys(validationErrors).length === 0 &&
        Object.keys(columnTitleErrors).length === 0
      }
      loading={loading}
      wide={true}
      shouldCloseOnOverlayClick={false} // Prevent closing on outside click
    >
      <div className="manage-test-data-modal">
        <Tabs>
          <TabList>
            {envKeys.map((env, index) => {
              const envKey = `testdata_${env.toLowerCase()}`;
              return (
                <Tab
                  key={index}
                  style={{
                    color: hasValidationError(envKey) ? 'red' : '#5f60ff',
                  }}
                  onClick={() => {
                    setCurrentEnv(env);
                  }}
                >
                  <span>
                    {env}{' '}
                    {hasValidationError(envKey) && (
                      <i
                        className="bi bi-exclamation-circle"
                        style={{ color: 'red', marginLeft: '5px' }}
                      ></i>
                    )}
                  </span>
                </Tab>
              );
            })}
          </TabList>
          {envKeys.map((env, index) => (
            <TabPanel key={index}>
              {testDataState.length > 1 ? (
                testDataState.map((element) => (
                  <CollapsibleContent
                    key={element.id}
                    title={element.displayname}
                  >
                    {renderTestDataSection(element, env)}
                  </CollapsibleContent>
                ))
              ) : (
                <div style={{ overflowX: 'auto' }}>
                  {renderTestDataSection(testDataState[0], env)}
                </div>
              )}
            </TabPanel>
          ))}
        </Tabs>
      </div>
      {showConfirmClose && (
        <div className="confirm-close-dialog">
          <p>Are you sure you want to close the modal?</p>
          <button onClick={handleConfirmClose} className="btn btn-primary">
            Yes
          </button>
          <button onClick={handleCancelClose} className="btn btn-secondary">
            No
          </button>
        </div>
      )}
    </Modal>
  );
};

export default ManageTestDataModal;
