import { getNavigationData } from 'utils/api.service';
import {
  updateAvailableCategories,
  updateUserInfo,
} from 'store/actions/app_actions';
import { addNewNotification } from './notification_actions';

export const Types = {
  SET_CATEGORIES: 'menu/SET_CATEGORIES',
};

const setNavigationCategories = (categories) => {
  return {
    type: Types.SET_CATEGORIES,
    categories,
  };
};

export const fetchNavigationCategories = () => {
  return (dispatch, getState) => {
    const done = (data) => {
      dispatch(setNavigationCategories(data));
      dispatch(updateAvailableCategories(data));
      dispatch(updateUserInfo(data.userinfo));
    };

    return getNavigationData()
      .then((response) => {
        done(response.data);
      })
      .catch((error) => {
        dispatch(addNewNotification(error.message, 'danger'));
      });
  };
};
