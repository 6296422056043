import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Modal from 'components/Modal';
import List from 'components/List';
import MultipleSelection from 'components/MultipleSelection';
import CollapsibleContent from 'components/CollapsibleContent';
import FlashMessage from 'components/FlashMessage';
import TestData from 'components/TestData/TestData.js';
import Tooltip from 'components/Tooltip';
import CustomSKUInput from 'components/CustomSKUInput';

import { addNewNotification } from 'store/actions/notification_actions';
import TestDataSection from '../TestDataSection';
import { v4 as uuidv4 } from 'uuid';
import { MAX_REPLICATION } from '../../utils/consts';
import ReactTooltip from 'react-tooltip';

class MetafieldsModal extends React.Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    environment: PropTypes.string.isRequired,
    elements: PropTypes.array.isRequired,
    metafields: PropTypes.array.isRequired,
    readOnlyFields: PropTypes.array,
    hiddenFields: PropTypes.array,
    commonFields: PropTypes.array,
    default: PropTypes.array,
    valueFromElements: PropTypes.bool,
    title: PropTypes.string.isRequired,
    onChangeProject: PropTypes.func,
  };

  static defaultProps = {
    readOnlyFields: [],
    hiddenFields: [],
    valueFromElements: false,
    commonFields: [],
    default: [],
    onChangeProject: () => {},
  };

  state = {
    payload: {},
    testDataFiles: {},
    errors: {},
    defaultValuesSet: false,
    processingRequest: false,
    temporaryLineItems: {},
    fileType: 'testdatafile',
    fileInputKey: '',
    testDataClones: [],
  };

  constructor() {
    super();

    this.onSubmitFormClick = this.onSubmitFormClick.bind(this);
    this.onTestDataFileAttached = this.onTestDataFileAttached.bind(this);
    this.updateTemporaryLineItem = this.updateTemporaryLineItem.bind(this);
    this.handleSKUs = this.handleSKUs.bind(this);
    this.onTestDataRemove = this.onTestDataRemove.bind(this);
    this.onTestDataAdd = this.onTestDataAdd.bind(this);
    this.onFileTypeChange = this.onFileTypeChange.bind(this);
    this.fileInputRef = React.createRef();
  }

  componentDidMount() {
    const { payload, testDataClones } = this.state;

    this.props.elements.forEach((element) => {
      // setting the payload to an empty object
      payload[element.id] = {};

      this.props.metafields.forEach((field) => {
        if (field.field) {
          let defaultValue = field.default ? field.default : undefined;

          // custom conditions
          if (this.props.valueFromElements) {
            defaultValue = element[field.field];
          } else if (field.field === 'env') {
            defaultValue = this.props.environment;
          } else if (field.field === 'awsaccountid') {
            if (field.options[0]) {
              defaultValue = field.options[0].id;
            }
          } else if (
            field.field === 'testdata' ||
            field.field === 'parameters'
          ) {
            defaultValue = {};
          } else if (field.field === 'usertype') {
            if (defaultValue === 'all') {
              defaultValue = field.default;
            }
          }

          if (field.field === 'toproject') {
            defaultValue = field.default;
          }

          payload[element.id][field.field] = defaultValue;
          // set default values for testdata fields as testdata_ENV: [{_tags: [], _name: '', ...}]
          // if not set and testdata is a hidden field (create/edit/bulkEdit/clone/bulkClone test case)
          if (
            field.field === 'testdata' &&
            this.props.hiddenFields.includes('testdata')
          ) {
            const envlist = this.props.parent.envlist.map((env) => env.env);
            envlist.forEach((env) => {
              const testDataEnvKey = `testdata_${env}`.toLocaleLowerCase();
              const isOldTestDataFormat = !element[testDataEnvKey];
              if (isOldTestDataFormat) {
                // initialize payload with testdata field with new format
                payload[element.id][testDataEnvKey] = [
                  {
                    _tags: [] /*tags is an array for initialization */,
                    _name: '',
                    // spread old testdata fields into new format
                    ...payload[element.id][field.field][env],
                  },
                ];
              } else {
                payload[element.id][testDataEnvKey] = element[testDataEnvKey];
              }
            });
          }

          // initialize testdataClones for testdata replication
          if (field.field === 'testdata') {
            testDataClones.push({
              id: element.id,
              testCaseId: element.id,
              // testdata field itself
              ...element[field.field],
            });
          }
        }
      });
    });

    this.setState({ payload, defaultValuesSet: true, testDataClones });
  }

  // user actions
  onSubmitFormClick() {
    const { state } = this;
    const { payload, testDataClones } = state;
    const testcases = Object.keys(payload);
    const { environment, title } = this.props;
    const isSubmitWindow =
      title && title.toLowerCase().indexOf('submit') !== -1;
    let request;

    this.setState({ processingRequest: true });

    testcases.forEach((testcaseid) => {
      const testcase = payload[testcaseid];
      const tempLineItems = state.temporaryLineItems[testcaseid] || [];
      const lineItems = payload[testcaseid].lineitems || [];
      testcase.lineitems = [...lineItems, ...tempLineItems].filter((l) => l);
    });

    const updatedPayload = { ...payload };
    // merge cloned testdata updates into payload
    if (isSubmitWindow) {
      testDataClones.forEach((testData) => {
        updatedPayload[testData.id].testdata[environment] =
          testData[environment];
      });
    }

    request = this.props.onSubmit(
      updatedPayload,
      this.state.testDataFiles,
      this.state.fileType
    );

    if (request) {
      request
        .then((response) => {
          if (response) {
            this.props.addNewNotification(response.data.message, 'success');
          }
        })
        .catch(({ response }) => {
          if (response) {
            let detail = response.data.detail;

            if (Array.isArray(detail)) {
              detail = detail.map((d) => `${d.testcasename}: ${d.message}`);
            }

            this.props.addNewNotification(detail, 'danger');
          }

          this.setState({
            processingRequest: false,
          });
        });
    }
  }

  onFormFieldChange(parentKey) {
    return ({ target }) => {
      const { name, value, objectPath } = target;

      this.setState((state) => {
        const { payload } = state;

        if (payload[parentKey]) {
          if (objectPath) {
            let obj = payload[parentKey][name];
            let objectPathArr = objectPath.split(':');
            for (let i = 0; i < objectPathArr.length - 1; i++) {
              obj = obj[objectPathArr[i]];
            }
            obj[objectPathArr[objectPathArr.length - 1]] = value;
          } else {
            payload[parentKey][name] = value === '' ? undefined : value;
          }
        }

        return { payload };
      });
    };
  }

  onTestDataFileAttached(key, env) {
    return (e) => {
      const file = e.target.files[0];
      this.setState((state) => {
        const { testDataFiles } = state;

        if (!testDataFiles[key]) {
          testDataFiles[key] = {};
        }
        if (!testDataFiles[key][env]) {
          testDataFiles[key][env] = {};
        }
        testDataFiles[key][env] = file;

        return { testDataFiles };
      });
    };
  }

  updateTemporaryLineItem(key) {
    return (selectedOption, quantity, skuType, displayName) => {
      const lineItemKey =
        this.props.channelName === 'BIC' ? 'priceid' : 'partnumber';
      const { temporaryLineItems } = this.state;

      if (quantity && selectedOption && !selectedOption.sku) {
        selectedOption.sku = 'default';
      }

      if (selectedOption.sku) {
        if (!temporaryLineItems[key]) {
          temporaryLineItems[key] = [];
        }

        const existingLineItemIdx = temporaryLineItems[key].findIndex(
          (l) => l.skutype === skuType
        );

        if (existingLineItemIdx >= 0) {
          temporaryLineItems[key].splice(existingLineItemIdx, 1);
        }

        temporaryLineItems[key].push({
          [lineItemKey]: selectedOption.sku,
          quantity: +quantity,
          skutype: skuType,
          displayname: displayName,
          productname: selectedOption.name,
        });
      } else {
        if (temporaryLineItems[key]) {
          const existingLineItemIdx = temporaryLineItems[key].findIndex(
            (l) => l.skutype === skuType
          );

          if (existingLineItemIdx >= 0) {
            temporaryLineItems[key].splice(existingLineItemIdx, 1);
          }
        }
      }

      this.setState({ temporaryLineItems });
    };
  }

  handleSKUs(key) {
    return (lineitems) => {
      this.setState((state) => {
        const { payload } = state;

        payload[key].lineitems = lineitems;

        return { payload };
      });
    };
  }

  canSubmitForm() {
    return !!this.props.onSubmit;
  }

  handleInputChange = (testDataCloneId, key, value) => {
    const { environment } = this.props;
    this.setState((prevState) => {
      const newTestData = [...prevState.testDataClones];
      const testDataIndex = newTestData.findIndex(
        ({ id }) => id === testDataCloneId
      );
      newTestData[testDataIndex] = {
        ...newTestData[testDataIndex], // Shallow clone the testdata object
        [environment]: {
          ...newTestData[testDataIndex][environment], // Shallow clone the environment object
          [key]: value, // Update the specific key with the new value
        },
      };
      return { testDataClones: newTestData };
    });
  };

  renderInput(field, key, env) {
    let value = this.state.payload[key]
      ? this.state.payload[key][field.field]
      : '';

    let containerWidth = 6;
    let input = null;

    // skip hidden fields
    if (this.props.hiddenFields.includes(field.field)) {
      return;
    }
    const types = {
      contact: 'email',
      password: 'password',
      default: 'text',
    };

    switch (field.type) {
      case 'string':
      case 'number':
      case 'date':
        if (
          this.props.readOnlyFields.includes(field.field) &&
          value &&
          value.includes('http')
        ) {
          input = (
            <a
              href={value}
              className="read-only-input"
              rel="noopener noreferrer"
              target="_blank"
            >
              {value}
            </a>
          );
        } else {
          input = (
            <input
              name={field.field}
              type={types[field.field] ? types[field.field] : types.default}
              placeholder={field.default}
              value={value}
              onChange={this.onFormFieldChange(key)}
              readOnly={this.props.readOnlyFields.includes(field.field)}
              className="form-control"
              // disabled={
              //   field?.disable?.tools === this.state.payload[key]?.tools
              // }
            />
          );
        }

        break;
      case 'text':
        input = (
          <textarea
            name={field.field}
            placeholder={field.default}
            onChange={this.onFormFieldChange(key)}
            className="form-control"
          ></textarea>
        );

        break;
      case 'list':
        let objectPath;
        const { environment } = this.props;

        if (field.field === 'testdata' && !this.state.processingRequest) {
          if (!this.state.payload[key][field.field][env]) {
            this.setState((prevState) => {
              const updatedPayload = { ...prevState.payload };
              updatedPayload[key][field.field][env] = {};
              return { payload: updatedPayload };
            });
          }

          objectPath = env;
          value = this.state.payload[key][field.field][env];
        }

        if (field.field === 'notsupportedenv') {
          const selectionProps = {
            options: this.props.parent.supportedenv,
            name: field.field,
            onChange: this.onFormFieldChange(key),
            initialValue: value,
          };

          input = <MultipleSelection {...selectionProps} />;
        } else {
          let isSubmitWindow = false;
          let showFileUpload = true;
          let element = this.props.elements.filter((e) => e.id === key)[0];

          if (
            this.props.title &&
            this.props.title.toLowerCase().indexOf('submit') !== -1
          ) {
            isSubmitWindow = true;

            if (
              !element.testdata[this.props.environment] ||
              !element.testdata[this.props.environment].testdatafile
            ) {
              showFileUpload = false;
            }
          }

          input = (
            <List
              name={field.field}
              objectPath={objectPath}
              onChange={this.onFormFieldChange(key)}
              initialValue={value}
              singleValue={['supportedenv', 'notsupportedenv'].includes(
                field.field
              )}
              readOnly={this.props.readOnlyFields.includes(field.field)}
              isSubmitWindow={isSubmitWindow}
              editable
            />
          );

          // rendering new replicable & editable testDataList
          // in a new component to isolate replicate-testdata feature
          // from current List
          if (isSubmitWindow) {
            const deleteTestData = (testDataId) => {
              this.setState((prevState) => {
                const newTestData = prevState.testDataClones.filter(
                  ({ id }) => id !== testDataId
                );
                const updatedPayload = { ...prevState.payload };
                delete updatedPayload[testDataId];
                return { testDataClones: newTestData, payload: updatedPayload };
              });
            };
            containerWidth = 12;
            input = [
              <div>
                <div className="test-data-list">
                  {this.state.testDataClones
                    .filter(({ testCaseId }) => testCaseId === key)
                    .map((testDataItem, index) => (
                      <TestData
                        key={testDataItem.id}
                        testDataEntries={testDataItem[environment]}
                        onInputChange={(key, value) =>
                          this.handleInputChange(testDataItem.id, key, value)
                        }
                        onDeleteTestData={() => deleteTestData(testDataItem.id)}
                        isDeleteDisabled={index === 0}
                      />
                    ))}
                </div>
                <div className="test-data-add__btn-right">
                  <ReactTooltip id="add-tooltip" place="top" effect="solid">
                    Add test data clone
                  </ReactTooltip>
                  <button
                    onClick={() => this.replicateTestData(element.id)}
                    key={element.id}
                    disabled={
                      this.state.testDataClones.filter(
                        ({ testCaseId }) => testCaseId === key
                      ).length === MAX_REPLICATION
                    }
                    className="test-data-add"
                    data-tip
                    data-for="add-tooltip"
                  >
                    +
                  </button>
                </div>
              </div>,
            ];
          }

          // custom behavior
          if (field.field === 'testdata' && showFileUpload) {
            containerWidth = 12;

            if (!this.props.readOnlyFields.includes(field.field)) {
              input = [
                <div>
                  <div className="form-group test-data">
                    <select
                      className="form-control"
                      onChange={this.onFileTypeChange}
                    >
                      <option value="testdatafile" selected>
                        Test Data File
                      </option>
                      <option value="testiterationfile">Iteration File</option>
                    </select>
                  </div>
                  <input
                    ref={this.fileInputRef}
                    type="file"
                    {...(this.state.fileType === 'testiterationfile'
                      ? {
                          accept:
                            '.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                        }
                      : {})}
                    onChange={this.onTestDataFileAttached(key, env)}
                  />
                  <p className="file-note">
                    <em>
                      **Uploading a new file will overwrite the existing file
                      for this test execution.
                    </em>
                  </p>
                </div>,
                input,
              ];
            }
          }
        }

        break;
      case 'select':
        const selectProps = {
          defaultValue: value,
          name: field.field,
          onChange: this.onFormFieldChange(key),
          disabled: this.props.readOnlyFields.includes(field.field),
          className: 'form-control',
        };

        if (field.options) {
          let fieldOptions = field.options;

          if (field.options[this.props.environment]) {
            fieldOptions = field.options[this.props.environment];
          }

          if (field.field === 'usertype') {
            fieldOptions = field.options;
          }

          const options = fieldOptions.map((option) => {
            if (typeof option === 'string') {
              return (
                <option key={option} value={option}>
                  {option}
                </option>
              );
            } else if (typeof option === 'object') {
              let id = option.id;
              let name = option.name;

              if (!option.id && !option.name) {
                [name, id] = Object.entries(option)[0];
              }

              return (
                <option key={id} value={id}>
                  {name}
                </option>
              );
            }

            return null;
          });

          input = <select {...selectProps}>{options}</select>;
        }

        break;
      case 'lineitems':
        const payload = this.state.payload[key];

        if (payload && (payload.salesorg || payload.store)) {
          const element = this.props.elements.find((s) => s.testcaseid === key);
          const lineitems = this.props.metafields.find(
            (f) => f.field === 'lineitems'
          );
          const customSKUInputProps = {
            channel: element.channel,
            onLineItemsUpdated: this.handleSKUs(key),
            isMultiple: element.ismultiple,
            skutype: element.skutype,
            region: payload.salesorg || payload.store,
            environment: this.props.environment,
            updateTemporaryLineItem: this.updateTemporaryLineItem(key),
            fulfillment: 'custom',
            autoload: false,
            placeholder: 'default',
            upwards: true,
            lineitems: lineitems.lineitems,
            default: element.lineitems,
          };

          return (
            <div className="col-sm-12" key={2}>
              <CustomSKUInput {...customSKUInputProps} />
            </div>
          );
        }

        return null;
      default:
        break;
    }

    if (input) {
      const { errors } = this.state;
      const tooltip = field.description ? (
        <Tooltip text={field.description} />
      ) : null;
      const optional =
        field.mandatory === 'false' ? (
          <span className="optional">Optional</span>
        ) : (
          <span className="mandatory">*</span>
        );
      const hasError =
        errors[key] && errors[key][field.field] ? ' has-error' : '';
      let smallMessage = null;
      let errorMessage = '';

      if (hasError) {
        errorMessage = errors[key][field.field];
      }

      if (field.small) {
        smallMessage = <small>{field.small}</small>;
      }

      return (
        <div
          className={`col-sm-${containerWidth} form-group${hasError}`}
          key={field.field}
        >
          <label htmlFor={field.field}>
            {field.name.trim()}
            {optional}
            {tooltip}
          </label>
          {input}
          {smallMessage}

          {hasError && (
            <span className="has-error-reason">
              {field.name} {errorMessage}
            </span>
          )}
        </div>
      );
    }

    return input;
  }

  onTestDataRemove(parentKey, env) {
    this.setState((state) => {
      const { payload } = state;
      payload[parentKey].testdata[env] = {};
      return { payload };
    });
  }

  onFileTypeChange(e) {
    this.fileInputRef.current.value = '';
    this.setState({ fileType: e.target.value });
  }

  onTestDataAdd(parentKey, env) {
    this.setState((state) => {
      const { payload } = state;
      let allEnvData = Object.values(payload[parentKey].testdata).reduce(
        (accumulator, currentCategory) => ({
          ...accumulator,
          ...currentCategory,
        })
      );
      payload[parentKey].testdata[env] = allEnvData;
      return { payload };
    });
  }

  replicateTestData(testCaseId) {
    const { payload, testDataClones } = this.state;
    const clonedTestData = { ...payload[testCaseId].testdata };
    const newTestCaseId = uuidv4();
    const newTestDataClones = [
      ...testDataClones,
      { id: newTestCaseId, testCaseId, ...clonedTestData },
    ];
    const updatedPayload = {
      ...payload,
      [newTestCaseId]: {
        ...payload[testCaseId],
        testdata: { ...clonedTestData },
      },
    };
    this.setState({
      payload: updatedPayload,
      testDataClones: newTestDataClones,
    });
  }

  renderElement(element, metafields, envlist, modal) {
    let payload = this.state.payload[element.id];
    let inputs = [];
    if (modal && modal === 'addTestData') {
      envlist.forEach((env) => {
        let temp = metafields.map((field) => {
          if (field.name === 'Environment') {
            field.default = env;
          }
          return this.renderInput(field, element.id, env.toUpperCase());
        });

        let testDataContent = (
          <div className="add-test-data-env-section">{temp}</div>
        );

        let envData = {};
        let hasData = false;
        if (
          payload.hasOwnProperty('testdata') &&
          payload.testdata[env] &&
          Object.keys(payload.testdata[env]).length > 0
        ) {
          hasData = true;
          envData = payload.testdata[env];
        }
        inputs.push(
          <TestDataSection
            elementId={element.id}
            onTestDataAdd={this.onTestDataAdd}
            onTestDataRemove={this.onTestDataRemove}
            content={testDataContent}
            hasData={hasData}
            data={envData}
            env={env}
          />
        );
      });
    } else {
      const env = this.props.environment
        ? this.props.environment.toUpperCase()
        : '';

      inputs.push(
        metafields.map((field) => this.renderInput(field, element.id, env))
      );
    }
    return inputs;
  }

  renderFields(elements, metafields, envlist, modal) {
    const fields = [];

    if (elements.length > 0) {
      if (elements.length > 1) {
        elements.forEach((element) => {
          fields.push(
            <CollapsibleContent key={element.id} title={element.displayname}>
              <div className="row">
                {this.renderElement(element, metafields, envlist, modal)}
              </div>
            </CollapsibleContent>
          );
        });
      } else {
        fields.push(
          <div key="singlefieldgroup" className="row">
            {this.renderElement(elements[0], metafields, envlist, modal)}
          </div>
        );
      }
    }

    return (
      <React.Fragment>
        <div className="modal-form-common-fields">
          <div className="row">{this.props.commonFields}</div>
        </div>

        {fields}
        {this.props.children}
      </React.Fragment>
    );
  }
  // dinamycally render bulk edit or clone modal
  // depending on the presence of toproject metafield
  renderBulkModal() {
    return (
      <div>
        {this.props.metafields
          .map(({ field }) => field)
          .includes('toproject') ? (
          <div className="form-group bulk-modal-form-group">
            <label htmlFor="target-project" className="testcases-list__heading">
              Clone to Project
            </label>
            <select
              className="form-control"
              id="target-project"
              name="toproject"
              defaultValue={this.props.parent.projectname}
              onChange={this.props.onChangeProject}
            >
              {this.props.metafields
                .find(({ field }) => field === 'toproject')
                .options.map((project) => (
                  <option key={project.id} value={project.id}>
                    {project.name}
                  </option>
                ))}
            </select>
          </div>
        ) : null}
        <span className="testcases-list__heading">
          <h6>{`${this.props.elements.length} test cases selected`}</h6>
        </span>
        <hr />
      </div>
    );
  }

  render() {
    if (this.state.defaultValuesSet) {
      const fields = this.renderFields(
        this.props.elements,
        this.props.metafields,
        this.props.parent
          ? Object.values(this.props.parent.envlist).map((e) => e.displayname)
          : null,
        this.props.modal || null
      );
      const modalProps = {
        onClose: this.props.onClose,
        onSubmit: this.onSubmitFormClick,
        submitButtonText: 'Submit',
        title: this.props.title,
        submitEnabled: this.canSubmitForm(),
        loading: this.state.processingRequest,
      };

      return (
        <Modal {...modalProps}>
          <FlashMessage />
          <div className="modal-form modal-content-wrapper">
            {(!this.props.hasOwnProperty('showFieldMandatoryLabelHeader') ||
              this.props.showFieldMandatoryLabelHeader) && (
              <span className="modal-form-legend">
                Fields marked with an <strong>*</strong> are mandatory.
              </span>
            )}
            {this.props.title.toLowerCase().includes('bulk') &&
              this.renderBulkModal()}
            {fields}
          </div>
        </Modal>
      );
    }

    return null;
  }
}

const mapDispatchToProps = (dispatch) => ({
  addNewNotification: (message, category) =>
    dispatch(addNewNotification(message, category)),
});

export default connect(null, mapDispatchToProps)(MetafieldsModal);
