import { connect } from 'react-redux';

import Products from './Products';
import Orders from './Orders';
import withChannelsAndTestCases from 'hocs/withChannelsAndTestCases';

const components = {
  Products,
  Orders,
  Simulation: Orders,
  NewBuyingExperience: Orders
};
const applyQuarryHOC = (component, category, accessor, pageHeader = '') => {
  const mapStateToProps = state => ({
    category: category,
    activeEnvironment: state.app.environment.Orders,
    userInfo: state.app.userInfo,
  });

  return connect(mapStateToProps)(withChannelsAndTestCases({ component, category, accessor, pageHeader })(components[component]));
};

const products = applyQuarryHOC('Products', 'products', 'results');
const orders = applyQuarryHOC('Orders', 'orders', 'Orders', 'BuySell');
const simulation = applyQuarryHOC('Simulation', 'orders', 'Orders');
const newBuying = applyQuarryHOC('NewBuyingExperience', 'orders', 'Orders');
//const buySell = applyQuarryHOC('BuySell', 'orders', 'Orders');

export { products as Products, orders as Orders, simulation as Simulation, newBuying as NewBuyingExperience };
