const getOldFormatTestCases = (testCases) => {
  return testCases.filter(
    (testCase) =>
      !Object.keys(testCase).some((key) => key.startsWith('testdata_'))
  );
};

const transformToNewFormat = (testCases) => {
  const oldFormatTestCases = getOldFormatTestCases(testCases);
  return oldFormatTestCases.map((testCase) => {
    const testData = testCase.testdata || {};
    const transformedTestdata = Object.keys(testData).reduce((acc, key) => {
      const envKey = `testdata_${key.toLowerCase()}`;
      acc[envKey] = Array.isArray(testCase.testdata[key])
        ? testCase.testdata[key].map((item) => ({
            _name: item._name || '',
            _tags: item._tags || '',
            ...item,
          }))
        : [
            {
              _name: '',
              _tags: '',
              ...testCase.testdata[key],
            },
          ];
      return acc;
    }, {});
    const columnsOrder = Object.keys(transformedTestdata).reduce(
      (acc, envKey) => {
        acc[envKey] = [
          ...Object.keys(transformedTestdata[envKey][0]).filter(
            (key) => key !== '_name' && key !== '_tags'
          ),
        ];
        return acc;
      },
      {}
    );
    return {
      ...testCase,
      ...transformedTestdata,
      columnsOrder,
    };
  });
};

const setColumnsOrder = (testCase) => {
  return Object.keys(testCase)
    .filter((key) => key.startsWith('testdata_'))
    .reduce((acc, envKey) => {
      const keys = testCase[envKey].reduce((keyAcc, item) => {
        return [...keyAcc, ...Object.keys(item)];
      }, []);
      const uniqueKeys = [...new Set(keys)];
      const filteredKeys = uniqueKeys.filter(
        (key) => key !== '_name' && key !== '_tags'
      );
      acc[envKey] = filteredKeys;
      return acc;
    }, {});
};

const parseTestDataTags = (testCase) => {
  return Object.keys(testCase)
    .filter((key) => key.startsWith('testdata_'))
    .reduce((acc, envKey) => {
      acc[envKey] = testCase[envKey].map((item) => ({
        ...item,
        _tags: Array.isArray(item._tags) ? item._tags.join(' ') : '',
      }));
      return acc;
    }, {});
};

const getNewFormatTestCases = (testCases) => {
  return testCases.filter((testCase) =>
    Object.keys(testCase).some(
      (key) => key.startsWith('testdata_') && Array.isArray(testCase[key])
    )
  );
};

const parseTestCasesToTableView = (testCases) => {
  const newFormatTestCases = getNewFormatTestCases(testCases);
  return newFormatTestCases.map((testCase) => {
    const columnsOrder = setColumnsOrder(testCase);
    const parsedTestData = parseTestDataTags(testCase);
    return {
      ...testCase,
      ...parsedTestData,
      columnsOrder,
    };
  });
};

export const transformTestData = (testCases, includeColumnsOrder = true) => {
  const transformedOldTestCases = transformToNewFormat(testCases);
  const transformedNewTestCases = parseTestCasesToTableView(testCases);
  const transformedTestCases = [
    ...transformedOldTestCases,
    ...transformedNewTestCases,
  ];
  if (includeColumnsOrder) {
    return transformedTestCases;
  }
  return transformedTestCases.map((testCase) => {
    const { columnsOrder, ...rest } = testCase;
    return rest;
  });
};
