import React from 'react';
import { connect } from 'react-redux';

import LoadingSpinner from 'components/LoadingSpinner';
import PageTitle from 'components/PageTitle';
import CollapsibleDropdown from 'components/CollapsibleDropdown';
import TestSelection from 'components/TestSelection';

import {
  getTestSuiteDetails,
  submitNewTestSuite,
  updateTestSuite,
  getEnvironments,
} from 'store/actions/test_suites_actions';
import { fetchCategoryDataQuarry } from 'utils/api.service';
import { getHostingProjects } from 'utils/api.service';

class CreateTestSuite extends React.Component {
  state = {
    services: [],
    service: {},
    lastUpdate: 0,
    loadingSubcategories: false,
    selectedChannels: [],
    environments: [],
    environment: '',
    testSuite: {},
    pageType: 'new',
    notificationEmail: '',
  };

  constructor() {
    super();

    this.onServiceChange = this.onServiceChange.bind(this);
    this.onEnvironmentChange = this.onEnvironmentChange.bind(this);
    this.onSubmitNewTestSuite = this.onSubmitNewTestSuite.bind(this);
    this.onUpdateTestSuite = this.onUpdateTestSuite.bind(this);
    this.getCurrentUserRole = this.getCurrentUserRole.bind(this);
  }

  componentDidMount() {
    this.filterServices();
    this.loadExistingTestSuite();
  }

  // start-up methods
  filterServices() {
    const testSuiteId = this.props.match.params.id;
    const validServices = ['thservices', 'projects'];
    const invalidCategories = ['testdata', 'Perf testing'];
    let service = {};

    // removing not supported categories
    const services = validServices
      .reduce((accumulator, currentCategory) => {
        return [...accumulator, ...this.props.categories[currentCategory]];
      }, [])
      .filter((category) => !invalidCategories.includes(category.servicename))
      .map((category) => {
        if (!category.servicename) {
          category.servicename = category.name;
        }

        return category;
      });

    // if not editing use first service
    if (!testSuiteId) {
      service = services[0];
    }

    this.setState(
      {
        services,
        service,
      },
      () => {
        this.onServiceSelected(service);
      }
    );
  }

  loadExistingTestSuite() {
    const testSuiteId = this.props.match.params.id;

    if (testSuiteId) {
      this.setState({
        pageType: 'edit',
      });

      getTestSuiteDetails(testSuiteId).then((response) => {
        const service = this.state.services.find(
          (s) => s.servicename === response.data.category
        );
        let channels = response.data.payload.category.reduce(
          (acc, c) => [...acc, ...c.subcategory],
          []
        );

        channels = channels.map((channel) => {
          const channelProps = {
            ...channel,
            org: service.servicename,
          };

          if (channel.testcases.length > 0) {
            const testcase = channel.testcases[0];

            channelProps.workstream = testcase.workstreamname;
            channelProps.projectname = testcase.projectname;
          }

          return channelProps;
        });

        this.setState(
          {
            testSuite: response.data,
            selectedChannels: channels,
            notificationEmail: response.data.payload.emailrecipients,
            environment: response.data.environment,
            environments: [
              {
                displayname: response.data.environment,
                env: response.data.environment,
              },
            ],
            service,
          },
          () => {
            this.onServiceSelected(service, false);
          }
        );
      });
    }
  }

  loadEnvironments(service) {
    this.setState({ loadingEnvironments: true });

    getEnvironments(service.servicename).then((response) => {
      this.setState({
        environments: response.data.envlist,
        environment: response.data.envlist[0].env,
        loadingEnvironments: false,
      });
    });
  }

  // http requests
  onServiceSelected(service, clearData = true) {
    this.setState({
      loadingSubcategories: true,
    });

    if (clearData) {
      this.setState({
        selectedChannels: [],
      });
    }

    if (service.servicename) {
      if (service.servicename === 'cjt') {
        const requests = service.categories.map((c) =>
          fetchCategoryDataQuarry(c.categoryid)
        );

        Promise.all(requests).then((responses) => {
          // responses is a collection of categories
          const categories = responses.map((response) => response.data);

          // replace service categories with object returned from API
          service.categories = categories;

          service.categories.forEach((category) => {
            category.subcategory.forEach((subcategory) => {
              subcategory.categoryid = category.categoryid;
            });
          });

          if (this.state.pageType !== 'edit') {
            this.loadEnvironments(service);
          }

          this.setState({
            service,
            loadingSubcategories: false,
          });
        });
      } else {
        const requests = service.workstream.map((c) =>
          getHostingProjects(service.org, c.workstreamname)
        );

        Promise.all(requests).then((responses) => {
          // responses is a collection of categories
          const categories = responses.map((response) => response.data);

          // replace service categories with object returned from API
          service.categories = categories
            .map((category) => {
              if (category.project[0]) {
                const workstream = service.workstream.find(
                  (w) => w.workstreamname === category.project[0].workstream
                );
                const subcategory = category.project.map((p) => ({
                  ...p,
                  subcategoryname: p.displayname,
                  subcategoryid: p.id,
                  org: service.servicename,
                }));

                return {
                  categoryname: workstream.displayname,
                  categoryid: workstream.id,
                  subcategory,
                };
              }

              return null;
            })
            .filter((c) => c);

          this.setState({
            service,
            loadingSubcategories: false,
          });
        });
      }
    }
  }

  // user actions
  onServiceChange(e) {
    const service = this.state.services.find(
      (category) => category.servicename === e.target.value
    );

    if (service.servicename) {
      this.setState(
        {
          service,
          lastUpdate: +new Date(),
        },
        () => {
          this.onServiceSelected(service);
          this.loadEnvironments(service);
        }
      );
    }
  }

  onEnvironmentChange(e) {
    this.setState({
      environment: e.target.value,
    });
  }

  onChannelClick(channel) {
    return () => {
      const { selectedChannels } = this.state;
      const channelIndex = selectedChannels.findIndex(
        (c) => c.subcategoryname === channel.subcategoryname
      );

      if (channelIndex >= 0) {
        selectedChannels.splice(channelIndex, 1);
      } else {
        selectedChannels.push(channel);
      }

      this.setState({
        selectedChannels,
      });
    };
  }

  onSubmitNewTestSuite(service, payload) {
    return this.props.submitNewTestSuite(service, payload).then(() => {
      this.props.history.push('/testsuites');
    });
  }

  onUpdateTestSuite(_, payload) {
    return this.props
      .updateTestSuite(this.state.testSuite.id, payload)
      .then(() => {
        this.props.history.push('/testsuites');
      });
  }

  // render methods
  renderServices(services) {
    const options = services.map((category) => {
      const value = category.servicename;

      return (
        <option key={value} value={value}>
          {category.displayName}
        </option>
      );
    });
    const selectProps = {
      value: this.state.testSuite.category,
      className: 'form-control',
      name: 'service',
      onChange: this.onServiceChange,
      id: 'service',
      disabled: this.state.pageType === 'edit',
    };

    return (
      <div className="form-group">
        <label htmlFor="service">Services</label>
        <select {...selectProps}>{options}</select>
      </div>
    );
  }

  renderEnvironments() {
    const loadingEnvironments =
      !this.state.environments.length || this.state.loadingEnvironments;
    let label;
    let content;

    if (loadingEnvironments) {
      content = <LoadingSpinner text="Loading environments" />;
    } else {
      const options = this.state.environments.map((environment) => {
        return (
          <option key={environment.env} value={environment.env}>
            {environment.displayname}
          </option>
        );
      });

      label = <label htmlFor="environment">Environment</label>;

      content = (
        <select
          className="form-control"
          name="environment"
          onChange={this.onEnvironmentChange}
          id="environment"
          disabled={this.state.pageType === 'edit'}
        >
          {options}
        </select>
      );
    }

    return (
      <div className="form-group">
        {label}
        {content}
      </div>
    );
  }

  renderCategories(service) {
    if (this.state.loadingSubcategories || this.state.environment === '') {
      return <LoadingSpinner text="Loading channels" />;
    }

    if (service.categories) {
      const categories = service.categories.map((category) => {
        const id = category.categoryid || category.id;
        const displayname = category.categoryname || category.displayname;
        let subcategories;

        if (category.subcategory) {
          subcategories = category.subcategory.map((subcategory) => {
            const selected = this.state.selectedChannels.find(
              (c) => c.subcategoryname === subcategory.subcategoryname
            );
            const checked = !!selected;

            return (
              <li key={subcategory.subcategoryid}>
                <input
                  type="checkbox"
                  checked={checked}
                  onClick={this.onChannelClick(subcategory)}
                />
                {subcategory.subcategoryname}
              </li>
            );
          });
        }

        return (
          <CollapsibleDropdown key={id} title={displayname}>
            <ul className="category-channel-selection">{subcategories}</ul>
          </CollapsibleDropdown>
        );
      });

      return (
        <div className="form-group">
          <label htmlFor="status">Categories</label>

          {categories}
        </div>
      );
    }

    return null;
  }

  getCurrentUserRole() {
    if (
      !this.props ||
      !this.props.userInfo ||
      !this.state.testSuite ||
      !this.state.testSuite.createdby
    )
      return '';
    if (
      this.props.userInfo.emailid.toLowerCase() ===
      this.state.testSuite.createdby.toLowerCase()
    ) {
      return 'Owner';
    } else {
      return this.state.testSuite.userlist.users.filter(
        (u) =>
          u.user.toLowerCase() === this.props.userInfo.emailid.toLowerCase()
      )[0].role;
    }
  }
  render() {
    const title =
      this.state.pageType === 'new'
        ? 'Create a new test suite'
        : 'Edit test suite';
    const services = this.renderServices(this.state.services);
    const environments = this.renderEnvironments();
    const categories = this.renderCategories(this.state.service);
    const testSelectionProps = {
      service: this.state.service,
      environment: this.state.environment,
      environments: this.state.environments,
      channels: this.state.selectedChannels,
      onSubmit:
        this.state.pageType === 'new'
          ? this.onSubmitNewTestSuite
          : this.onUpdateTestSuite,
      testSuiteName: this.state.testSuite.testsuitename,
      testSuiteTags: this.state.testSuite.payload?.tags,
      selectedTestCases: this.state.selectedChannels.reduce(
        (acc, c) => [...acc, ...(c.testcases || [])],
        []
      ),
      userInfo: this.props.userInfo,
      notificationEmail: this.state.notificationEmail,
    };
    let deleteButton = null;

    return (
      <main>
        <PageTitle title={title}>{deleteButton}</PageTitle>

        <div className="test-suites-wrapper">
          <section className="test-suites-services">
            {services}
            {environments}
            {categories}
          </section>

          <section className="test-suites-test-cases">
            <TestSelection {...testSelectionProps} />
          </section>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.menu.categories,
  userInfo: state.app.userInfo,
});

const mapDispatchToProps = (dispatch) => ({
  submitNewTestSuite: (service, payload) =>
    dispatch(submitNewTestSuite(service, payload)),
  updateTestSuite: (id, payload) => dispatch(updateTestSuite(id, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateTestSuite);
